// Custom Card Styling

.card {
  .card-header {
    // Format Dropdowns in Card Headings
    .dropdown {
      line-height: 1;
      .dropdown-menu {
        line-height: 1.5;
      }
    }
  }
  // Collapsable Card Styling
  .card-header[data-toggle="collapse"] {
    text-decoration: none;
    position: relative;
    padding: 0.75rem 3.25rem 0.75rem 1.25rem;
    &::after {
      position: absolute;
      right: 0;
      top: 0;
      padding-right: 1.725rem;
      line-height: 51px;
      font-weight: 900;
      content: '\f107';
      font-family: 'Font Awesome 6 Free';
      color: $gray-400;
    }
    &.collapsed {
      border-radius: $card-border-radius;
      &::after {
        content: '\f105';
      }
    }
  }
}
